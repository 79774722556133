import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';
import S from './Styles';
import { NafCenterAppType } from '../../../types/CategoryAppType';

export const nafCenterBasePath = 'bilverksted-og-tester';
const NafCenterServices = loadable(() => import('../pages/nafCenter/services'), {
  resolveComponent: (components) => components.NafCenterServices,
});

export const nafCenterPath = 'naf-senter/:centerSlug';

const NafCenterPageRoute = loadable(() => import('./NafCenterPageRoute'), {
  resolveComponent: (components) => components.NafCenterPageRoute,
});

const NafCentersDocumentRoute = loadable(() => import('./NafCentersDocumentRoute'), {
  resolveComponent: (components) => components.NafCentersDocumentRoute,
});

const NotFound = loadable(() => import('../pages/errors/NotFound'), {
  resolveComponent: (components) => components.NotFound,
});

export const NafCenterRouter = ({
  data,
  slug,
  appData,
}: {
  data?: any;
  slug?: string;
  appData?: NafCenterAppType;
}): ReactElement => (
  <S.Wrapper>
    <Switch>
      <Route exact path={`/${slug}`}>
        <NafCenterServices slug={slug || ''} data={data} appData={appData} />
      </Route>
      <Route exact path={`/${nafCenterBasePath}/${nafCenterPath}`} component={NafCenterPageRoute} />
      <Route exact path={`/${nafCenterBasePath}/:slug`} component={NafCentersDocumentRoute} />
      <Route exact path={`/${nafCenterBasePath}/:parentSlug/:slug`} component={NafCentersDocumentRoute} />
      <Route component={NotFound} />
    </Switch>
  </S.Wrapper>
);
